import type { FC, ReactElement } from 'react'
import { ChakraProps, HStack, LinkProps, useStyleConfig } from '@chakra-ui/react'
import { Box, Flex, Grid, GridItem, useBreakpointValue, VStack } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import type { FooterItem, SocialMediaItem } from 'ecosystem'
import GrandNextLink from 'ui/common/GrandNextLink'
import GrandSection from 'ui/storefront/layout/GrandSection'
import FooterColumn from 'ui/storefront/layout/FooterColumn'
import Image from 'next/image'
import klarnaImg from '#/src/images/Klarna.webp'
import nexiPaymentMethodsImg from '#/src/images/nexi_payment_methods.png'
import sveaPaymentMethodsImg from '#/src/images/svea_payment_methods.webp'
import tryggehandel from '#/src/images/tryggehandel.webp'
import granditudeLogo from '#/src/images/granditude_logo.png'
import appConfig from '#/src/appConfig'
import Link from 'next/link'
import { GrandImage } from 'ui'

interface IFooter {
  logo: ReactElement
  footerItems: FooterItem[]
  socialMediaItems: SocialMediaItem<LinkProps>[]
}

const FooterPaymentLogo = () => {
  switch (appConfig.paymentProviderConfig?.providerName ?? 'KLARNA') {
    case 'KLARNA':
      return <GrandImage alt="Klarna" src={klarnaImg} />
    case 'NEXI':
      return (
        <GrandImage
          alt="Nexi payment methods"
          src={nexiPaymentMethodsImg}
          maxW="600px"
          sizes="(min-width: 600px) 600px, 100vw"
        />
      )
    case 'SVEA':
      return (
        <Box
          width={{ base: 'full', md: '500px' }}
          height={{ base: '100px', md: '200px' }}
          position="relative">
          <GrandImage
            alt="Svea payment methods"
            src={sveaPaymentMethodsImg}
            fill
            objectFit="contain"
          />
        </Box>
      )
    // todo: add LEDYER logo
    case 'LEDYER':
      return <>LEDYER img</>
  }
}

const FooterSocialMedia = dynamic(
  async () => {
    const f = await import('ui/storefront')
    return f.FooterSocialMedia
  },
  {
    ssr: false
  }
) as any

const CustomFooter: FC<IFooter> = ({ logo, footerItems, socialMediaItems }) => {
  const styles = useStyleConfig('CustomFooter') as ChakraProps
  const itemColSpan = useBreakpointValue({
    base: 4,
    md: 1
  })

  const socialDesktop = useBreakpointValue({
    base: 'none',
    md: 'initial'
  })

  const socialMobile = useBreakpointValue({
    base: 'initial',
    md: 'none'
  })

  const fullItemColSpan = useBreakpointValue({
    base: 4
  })

  return (
    <GrandSection position="relative" sectionId="footer" sx={styles}>
      <Flex h={20} justifyContent="center" w="100%">
        <GrandNextLink aria-label="home" href="/">
          {logo}
        </GrandNextLink>
      </Flex>

      <Grid gap={4} templateColumns="repeat(4, 1fr)">
        {footerItems.slice(0, 3).map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key -- These items don't have an id
          <GridItem colSpan={itemColSpan} key={index} rowSpan={1}>
            <FooterColumn {...{ item }} />
          </GridItem>
        ))}

        <GridItem colSpan={itemColSpan} display={socialDesktop} rowSpan={2}>
          <VStack align="center" spacing={2}>
            <FooterSocialMedia align="center" items={socialMediaItems} />
            <Box>
              <Image alt="Tryggehandel" height={95} src={tryggehandel} width={95} />
            </Box>
          </VStack>
        </GridItem>

        {footerItems.slice(3).map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key -- These items don't have an id
          <GridItem colSpan={itemColSpan} key={index} rowSpan={1}>
            <FooterColumn {...{ item }} />
          </GridItem>
        ))}

        <GridItem colSpan={itemColSpan} display={socialMobile} rowSpan={2}>
          <VStack spacing={2} w="full">
            <FooterSocialMedia align="flex-start" items={socialMediaItems} />
            <Box>
              <Image alt="Tryggehandel" height={95} src={tryggehandel} width={95} />
            </Box>
          </VStack>
        </GridItem>

        <GridItem colSpan={fullItemColSpan} rowSpan={1}>
          <Flex justifyContent="center" py={12}>
            <FooterPaymentLogo />
          </Flex>
        </GridItem>

        <GridItem colSpan={fullItemColSpan} rowSpan={1} as={VStack}>
          <HStack alignItems="center" gap={4}>
            <Box opacity="0.7" fontSize={['xs', 'sm']}>
              Powered by
            </Box>
            <Link href="https://granditude.com" target="_blank">
              <GrandImage maxW="100px" src={granditudeLogo} alt="Granditude" sizes="100px" />
            </Link>
          </HStack>
        </GridItem>
      </Grid>
    </GrandSection>
  )
}

export default CustomFooter
